import {ssrGetListGames} from '~/graphql/operations/game/games-query.generated';
import * as Types from '../../../../graphql/types';

async function getListGame(
  locale?: string,
  skip: Types.GameCollection['skip'] = 0,
  limit: Types.GameCollection['limit'] = 10,
  query: any = {},
  sort?: any
) {
  const newQuery: any = {};

  const objQuery = Object.keys(query);
  newQuery['AND'] = [];
  if (objQuery.length) {
    objQuery.map(key => {
      const newKey = key?.toLocaleLowerCase() + '_contains_all';
      query[key].map((item: any) => {
        newQuery['AND'].push({[newKey]: item});
      });
    });
  }
  newQuery.title_exists = true;
  newQuery['AND'].push({"languagesPage_not_in": ["Thai","Indo"]})
  const {
    props: {data},
  } = await ssrGetListGames.getServerPage(
    {
      variables: {
        locale,
        skip,
        limit,
        where: newQuery,
        order: sort ?? '',
      },
    },
    {}
  );

  return data?.gameCollection;
}

export default getListGame;
