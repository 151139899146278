import {ssrGetOurPickMenu} from '~/graphql/operations/global/get-global-our-pick-menu.generated';

export async function getLitGameByIdBlock(id: string, locale: string) {
  const {
    props: {data},
  } = await ssrGetOurPickMenu.getServerPage(
    {
      variables: {
        id,
        locale,
      },
    },
    {}
  );
  return data?.gamesListing?.listItemCollection?.items ?? [];
}
