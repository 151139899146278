import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  // IconProps,
  INavigationBar,
} from '@wearesection/bandai-react-components/organisms/NavigationBar';
import theme from '@wearesection/bandai-react-components/theme';
import Cookies from 'js-cookie';
import {useRouter} from 'next/router';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {LIST_ID_GAME_OUR_PICK} from '~/constants';
import {handleConvertToLocaleContentful} from '~/utils';

import {
  // handleChangeLocale,
  handleConvertRegionTranslation,
  handleDetectDefaultLocale,
  handleDetectShowUpdateLocale,
  handleRedirectLanguageUtil,
} from '~/utils/locale-handler';

import getListGame from './get-list-game';
import {PLACEHOLDER_IMG} from '~/constants';
import {usePageSetting} from '~/providers/page-context.provider';
import {getGloablByType} from '~/utils/global-operations';
import RenderNextImage from '../../atoms/render-next-image';
import {getLitGameByIdBlock} from './get-list-game-by-id';

type IData = {
  title: string;
  href: string;
  imageUrl?: string;
};

type IDataPopular = {
  title: string;
  items: IData[];
};

import dynamic from 'next/dynamic';
import {GLOBAL_TYPE} from '~/constants/content-map-type';
import {useSelector} from 'react-redux';
import {handelFormatTitleStatus} from '../store-order-detail/store-order-detail';
import {setCookie} from 'cookies-next';
import {handleReturnLocaleStore} from '../home-page-mash-thead/home-page-mash-thead';

const NavigationBar = dynamic(
  () => import('@wearesection/bandai-react-components/organisms/NavigationBar'),
  {
    ssr: false,
  }
);

const InstantSearchCustom = dynamic(() => import('./instant-search'), {
  ssr: false,
});

const NavigationBarOrganisms = (props: INavigationBar) => {
  const router = useRouter();
  const {pathname, asPath, query, locales, locale, defaultLocale} = router;
  const preview = !!(process && process.env && process.env.NEXT_PUBLIC_PREVIEW);
  const [dataSubMenu, setDataSubMenu] = useState({});
  const [isShowSearch, setIsShowSearch] = useState<boolean>(false);
  const [showHits, setShowHits] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<string>('');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const refTimeOut = useRef<any>();
  const {
    openSearchPopup,
    setOpenSearchPopup,
    globalData,
    statusShowBannerUpdateLocale,
    setStatusShowBannerUpdateLocale,
    dataSlug,
  } = usePageSetting();
  const dataTranslation = globalData?.dataTranslation?.[0] ?? {};
  const isLoginSuccess = Cookies.get('isLoginSuccess');
  const [hadCart, setHadCart] = useState<boolean>(false);
  const listCart = useSelector((state: any) => state?.cart?.listCart || []);
  const user = useSelector((state: any) => state?.user?.dataUser);

  const LIST_KEY_POPUPAR: string[] = [
    'filter-game-platform-playstation-4',
    'filter-game-platform-nitendo-switch',
    'filter-game-platform-pc',
    'game-genre-adventure',
    'game-genre-action',
    'game-genre-role-playing-rpg',
  ];

  const ctx: any = {
    locales: locales,
    locale: locale,
    defaultLocale: defaultLocale,
  };

  const getValueSearch = (e: string) => {
    setValueSearch(e);
  };

  const dataTrending = (globalData as any)?.dataPopularTrending ?? {};

  useEffect(() => {
    if (listCart?.length) {
      setHadCart(true);
      return;
    } else {
      setHadCart(false);
    }
  }, [listCart]);

  useEffect(() => {
    const handleGetData = async () => {
      const promises: [
        ReturnType<typeof getListGame>,
        ReturnType<typeof getGloablByType>,
        ReturnType<typeof getLitGameByIdBlock>
      ] = [
        getListGame(
          handleConvertToLocaleContentful(ctx.locale as string) ?? '',
          0,
          6,
          {},
          'releaseDate_DESC'
        ),
        getGloablByType(
          handleConvertToLocaleContentful(ctx.locale as string),
          GLOBAL_TYPE.translation
        ),
        getLitGameByIdBlock(
          LIST_ID_GAME_OUR_PICK[handleConvertToLocaleContentful(ctx.locale as string)] ??
            LIST_ID_GAME_OUR_PICK['en-sg'],
          handleConvertToLocaleContentful(ctx.locale as string)
        ),
      ];
      const [dataGameLatest, dataTranslation, listDataOurPick] = await Promise.all(promises);
      let dataSub: any = [];
      const dataOurPickClone = listDataOurPick ?? [];
      const dataMenu: any = [];
      const dataOurPick: any = {
        title: dataTranslation['title-our-pick'],
        items:
          dataOurPickClone?.map((item: any) => ({
            title: item.title ?? '',
            mainImageNext: (
              <RenderNextImage
                src={
                  item.gamesFilterThumbnailImage?.media?.url ??
                  item.mainThumbnailImg?.media?.url ??
                  item.mastheadBackgroundImage?.media?.url ??
                  PLACEHOLDER_IMG
                }
                width={217}
                height={138}
                alt={''}
              />
            ),
            imageUrl: item.mastheadBackgroundImage?.media?.url ?? '',
            href: `/${locale}/games/${item.slug}` ?? '/',
          })) ?? [],
      };

      const dataLatest: IDataPopular = {
        title: dataTranslation['title-latest'],
        items: [],
      };

      // Data latest
      dataGameLatest &&
        dataGameLatest.items.map((item: any) => {
          const data: IData = {
            title: item?.title ?? '',
            href: `/${locale}/games/${item?.slug}` ?? '/',
          };
          dataLatest.items.push(data);
        });
      dataMenu.push(dataLatest);

      // Popular
      const dataPopular: IDataPopular = {
        title: dataTranslation['title-popular'] ?? '',
        items: [],
      };
      LIST_KEY_POPUPAR.map(item => {
        const data: IData = {
          title: dataTranslation[item],
          href: `/${ctx?.locale}/games?tag=${dataTranslation[item]}`,
        };
        dataPopular.items.push(data);
      });

      dataMenu.push(dataPopular);

      dataSub = {
        subMenu: dataMenu,
        ourPicks: dataOurPick ?? [],
      };

      setDataSubMenu(dataSub);
    };
    handleGetData();
  }, []);

  const handleReturnHref = (item: any, dataSlug: any, locale: string) => {
    const splitPathName = router.route.split('/');
    const localeTrim = locale?.slice(0, 2);

    if (item.label === '繁體中文') {
      //tw-tc
      if (splitPathName?.length >= 3) {
        if (dataSlug['zh-Hant']) {
          return `/${localeTrim === 'tw' ? 'tw-tc' : 'hk-tc'}/${splitPathName[1]}/${
            dataSlug['zh-Hant']
          }`;
        }
        return `/${localeTrim === 'tw' ? 'tw-tc' : 'hk-tc'}`;
      }
      return `/${localeTrim === 'tw' ? 'tw-tc' : 'hk-tc'}${router.asPath}`;
    }

    if (item.label === '简体中文') {
      //tw-tc
      if (splitPathName?.length >= 3) {
        if (dataSlug['zh-Hans']) {
          return `/${localeTrim === 'tw' ? 'tw-sc' : 'hk-sc'}/${splitPathName[1]}/${
            dataSlug['zh-Hans']
          }`;
        }
        return `/${localeTrim === 'tw' ? 'tw-sc' : 'hk-sc'}`;
      }
      return `/${localeTrim === 'tw' ? 'tw-sc' : 'hk-sc'}${router.asPath}`;
    }
  };

  const handleUpdateMenu = (dataSubMenu: any) => {
    const dataClone: any = {...props};
    const index = (dataClone?.menuProps ?? []).findIndex((item: any) => item.link === '/games');
    if (index !== -1) {
      dataClone.menuProps[index] = {
        ...dataClone.menuProps[index],
        ...dataSubMenu,
      };
    }
    // propsSearch
    dataClone.propsSearch = {
      ...dataClone.propsSearch,
      href: `/${locale}`,
      hrefSeeAll: `/${locale}/`,
    };

    // update url menu
    if (dataClone?.menuProps?.length) {
      const newMenuProps = dataClone.menuProps.map((item: any) => {
        if (item?.link === '/games') {
          item.seeAll.href = `/${locale}/games`;
        }
        if (
          item?.link === '/store' &&
          ['hk-tc', 'hk-sc', 'tw-tc', 'tw-sc'].includes(`${locale}`.toLowerCase())
        ) {
          return {
            ...item,
            link: `/${handleReturnLocaleStore(`${locale}`.toLowerCase())}/store`,
          };
        }
        return {
          ...item,
          link: item?.link.includes('http') ? item?.link : `/${locale}${item?.link}`,
        };
      });
      dataClone.menuProps = newMenuProps;
    }
    // update url cart
    if (dataClone?.iconProps?.length) {
      const newMenuIconProps = dataClone.iconProps.map((item: any) => {
        return {
          ...item,
          href:
            item?.href === '/cart'
              ? `/${handleReturnLocaleStore(`${locale}`.toLowerCase())}/cart`
              : item?.href,
        };
      });
      dataClone.iconProps = newMenuIconProps;
    }

    // update signInOut
    if (dataClone?.signInOut?.length) {
      const newSignInOut = dataClone.signInOut.map((item: any) => {
        if (['hk-tc', 'hk-sc', 'tw-tc', 'tw-sc'].includes(`${locale}`.toLowerCase())) {
          return {
            ...item,
            href: `/${handleReturnLocaleStore(`${locale}`.toLowerCase())}${item?.href}`,
          };
        }
        return {
          ...item,
          href: `/${locale}${item?.href}`,
        };
      });
      if (isLoginSuccess) {
        dataClone.signInOut = newSignInOut.splice(2, 3);
      } else {
        dataClone.signInOut = newSignInOut.splice(0, 2);
      }
    }
    dataClone.logged = !!isLoginSuccess;

    // update infoUser
    if (dataClone.infoUser)
      dataClone.infoUser = {
        name: `${dataTranslation['estore-account-welcome-hi'] || ''} ${handelFormatTitleStatus(
          user?.first_name || user?.last_name
        )}`,
        account: {
          ...dataClone.infoUser.account,
          href: `/${handleReturnLocaleStore(`${locale}`.toLowerCase())}/${
            dataClone.infoUser?.account?.href
          }`,
        },
        detail: {
          ...dataClone.infoUser.detail,
          href: `/${handleReturnLocaleStore(`${locale}`.toLowerCase())}${
            dataClone.infoUser?.detail?.href
          }`,
        },
        orders: {
          ...dataClone.infoUser.orders,
          href: `/${handleReturnLocaleStore(`${locale}`.toLowerCase())}${
            dataClone.infoUser?.orders?.href
          }`,
        },
        logout: {
          ...dataClone.infoUser.logout,
          href: `/${handleReturnLocaleStore(`${locale}`.toLowerCase())}${
            dataClone.infoUser?.logout?.href
          }`,
        },
      };

    // update button Props
    if (dataClone?.buttonProps?.length) {
      const newButtonProps = dataClone.buttonProps.map((item: any) => {
        return {
          ...item,
          externalLink: `/${handleReturnLocaleStore(`${locale}`.toLowerCase())}${
            item?.externalLink
          }`,
        };
      });

      dataClone.buttonProps = newButtonProps;
    }

    const localeTrim = locale?.slice(0, 2);

    const newLanguages = dataClone?.languages?.map((item: any) => {
      return {
        ...item,
        href: handleReturnHref(item, dataSlug, locale ?? ''),
      };
    });
    // update languages
    if (dataClone?.languages) {
      dataClone.languages = newLanguages;
    }

    if (localeTrim === 'hk' || localeTrim === 'tw') {
      if (locale === 'tw-sc' || locale === 'hk-sc') {
        dataClone.languagesDefault = '简体中文';
      } else {
        dataClone.languagesDefault = '繁體中文';
      }
    }

    dataClone.locale = locale;
    return dataClone;
  };

  const dataSubMenuMemo = handleUpdateMenu(dataSubMenu);

  const cookieCountry = Cookies.get('user-country');

  useEffect(() => {
    setStatusShowBannerUpdateLocale(
      !handleDetectShowUpdateLocale(
        // show banner update locale
        cookieCountry,
        router?.locale?.toLocaleLowerCase() ?? 'sea'
      )
    );
  }, [cookieCountry]);

  const handleCloseBannerRevamp = () => {
    setStatusShowBannerUpdateLocale(false);
    if (typeof window !== 'undefined' && pathname !== '/games/[slug]') {
      document.body.style.paddingTop = isMobile ? '73px' : isDesktop ? '80px' : '96px';
    } else {
      document.body.style.paddingTop = '0px';
    }
  };

  const handleRedirectLanguage = (selectedLang: string) => {
    setCookie('currentToken', '');
    handleRedirectLanguageUtil(selectedLang, router, pathname, query, asPath, dataSlug, true);
  };

  const listSlugsTranparentTheme: any = {'/games/[slug]': '/games/[slug]'};
  const {route} = useRouter();
  if (typeof window !== 'undefined') {
    if (statusShowBannerUpdateLocale) {
      document.body.style.paddingTop = listSlugsTranparentTheme[route]
        ? '0px'
        : isMobile
        ? '228px'
        : isDesktop
        ? '178px'
        : '194px';
    } else {
      document.body.style.paddingTop = listSlugsTranparentTheme[route]
        ? '0px'
        : isMobile
        ? '73px'
        : isDesktop
        ? '80px'
        : '96px';
    }
  }

  const [detectScrollTop, setDetectScrollTop] = React.useState(0);
  const [renderNavibarTheme, setRenderNavibarTheme] = React.useState<any>('transparent');

  if (typeof window !== 'undefined') {
    window.onscroll = function () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (refTimeOut.current) {
        window.clearTimeout(refTimeOut.current);
      }
      refTimeOut.current = setTimeout(() => {
        refTimeOut.current = undefined;
        if (scrollTop === 0) {
          setDetectScrollTop(scrollTop);
          return;
        }
        if (scrollTop !== 0 && detectScrollTop === 0) {
          setDetectScrollTop(scrollTop);
        }
      }, 200);
    };
  }

  React.useEffect(() => {
    setRenderNavibarTheme(
      listSlugsTranparentTheme[route] && detectScrollTop === 0 ? 'transparent' : 'light'
    );
  }, [detectScrollTop, listSlugsTranparentTheme, route]);

  const handleCloseSearchForm = () => {
    setIsShowSearch(false);
    setOpenSearchPopup(false);
  };

  const handleShowHit = (data: boolean) => {
    setShowHits(data);
  };

  useEffect(() => {
    setIsShowSearch(openSearchPopup);
  }, [openSearchPopup]);

  const handleShowTrending = () => {
    setShowHits(false);
  };

  const InstantSearchMemo = useMemo(
    () => (
      <InstantSearchCustom
        locale={locale ?? ''}
        handleCloseSearchForm={handleCloseSearchForm}
        handleShowHit={handleShowHit}
        isShowSearch={isShowSearch}
        handleShowTrending={handleShowTrending}
        getValueSearch={getValueSearch}
        dataSubMenuMemo={dataSubMenuMemo}
        showHits={showHits}
        valueSearch={valueSearch}
        dataTrending={dataTrending}
      />
    ),
    [locale, isShowSearch, dataSubMenuMemo, valueSearch, dataTrending, isLoginSuccess]
  );

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        height: {
          xs: '0px',
          md: isShowSearch ? '100px' : '0px',
        },
        background: 'none',
        width: '100%',
        zIndex: '888',
        pointerEvents: 'auto',
      }}
    >
      <Box
        sx={{
          zIndex: '10',
        }}
      >
        <NavigationBar
          {...dataSubMenuMemo}
          theme={(renderNavibarTheme as any) ?? 'light'}
          defaultLocation={handleDetectDefaultLocale(cookieCountry)}
          handleCloseBannerRevamp={handleCloseBannerRevamp}
          locationPrompt={{
            ...(dataSubMenuMemo as any)?.locationPrompt,
            isShowLocation: statusShowBannerUpdateLocale,
            defaultLocation: handleConvertRegionTranslation(
              handleDetectDefaultLocale(cookieCountry),
              locale as string
            ),
          }}
          handleShowLayoutSearch={() => {
            setIsShowSearch(true);
          }}
          handleRedirectLanguage={handleRedirectLanguage}
          notify={hadCart}
        />
      </Box>
      <Box
        sx={{
          width: '100vw',
          minHeight: {
            xs: '100vh',
            md: '100px',
          },
          padding: {
            xs: '26px 0px 24px 0',
            md: '40px 0px 24px 0',
          },
          position: 'fixed',
          top: isShowSearch ? '0px' : 'calc(-100vh - 50%)',
          transition: 'all 0.6s',
          background: '#fff',
          opacity: '1',
          zIndex: '50',

          '&::after': {
            content: '" "',
            width: '100%',
            height: '1px',
            backgroundColor: '#EAEAEA',
            position: 'absolute',
            bottom: '-1px',
          },
        }}
      >
        {InstantSearchMemo}
      </Box>

      <Box
        sx={{
          visibility: isShowSearch ? 'visible' : 'hidden',
          position: 'relative',
          minHeight: '100vh',
          width: '100%',
          background: '#1E1E1E',
          opacity: isShowSearch ? '0.8' : '0',
          zIndex: '5',
          transition: 'all 0.3s',
        }}
        onClick={() => {
          setIsShowSearch(false);
          setOpenSearchPopup(false);
        }}
      />
    </Box>
  );
};

export default React.memo(NavigationBarOrganisms);
