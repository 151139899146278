/* eslint-disable @typescript-eslint/no-explicit-any */
import {MAPPING_LOCALES_COUNTRY} from '~/constants';
import {FOR_STORE, handleConvertToLocaleContentful} from '.';
import {setCookie} from 'cookies-next';

export const handleConvertRegionTranslation = (region: string, locale: string) => {
  if (region === 'America') {
    if (locale === 'ko-KR' || locale === 'ko-kr') {
      return '대한민국';
    } else if (locale === 'tw-sc' || locale === 'hk-sc') {
      return '美国';
    } else if (locale === 'tw-tc' || locale === 'hk-tc' || locale === 'tc') {
      return '美國';
    } else {
      return 'America';
    }
  } else if (region === 'Europe') {
    if (locale === 'ko-KR' || locale === 'ko-kr') {
      return '유럽';
    } else if (locale === 'tw-sc' || locale === 'hk-sc') {
      return '欧洲';
    } else if (locale === 'tw-tc' || locale === 'hk-tc' || locale === 'tc') {
      return '歐洲';
    } else {
      return 'Europe';
    }
  } else if (region === 'Japan') {
    if (locale === 'ko-KR' || locale === 'ko-kr') {
      return '일본';
    } else if (locale === 'tw-sc' || locale === 'hk-sc') {
      return '日本';
    } else if (locale === 'tw-tc' || locale === 'hk-tc' || locale === 'tc') {
      return '日本';
    } else {
      return 'Japan';
    }
  } else if (region === 'Southeast Asia (SEA)') {
    if (locale === 'ko-KR' || locale === 'ko-kr') {
      return '동남아시아';
    } else if (locale === 'tw-sc' || locale === 'hk-sc') {
      return '东南亚';
    } else if (locale === 'tw-tc' || locale === 'hk-tc' || locale === 'tc') {
      return '東南亞';
    } else {
      return 'Southeast Asia (SEA)';
    }
  } else if (region === 'Hong Kong') {
    if (locale === 'ko-KR' || locale === 'ko-kr') {
      return '홍콩';
    } else if (locale === 'tw-sc' || locale === 'hk-sc') {
      return '香港';
    } else if (locale === 'tw-tc' || locale === 'hk-tc' || locale === 'tc') {
      return '香港';
    } else {
      return 'Hong Kong';
    }
  } else if (region === 'Taiwan') {
    if (locale === 'ko-KR' || locale === 'ko-kr') {
      return '대만';
    } else if (locale === 'tw-sc' || locale === 'hk-sc') {
      return '台湾';
    } else if (locale === 'tw-tc' || locale === 'hk-tc' || locale === 'tc') {
      return '台灣';
    } else {
      return 'Taiwan';
    }
  }
};

export const handleChangeLocale = (localeChange: string) => {
  switch (localeChange) {
    case 'tw-sc':
      return 'tw-tc';
    case 'tw-tc':
      return 'tw-sc';
    case 'hk-sc':
      return 'hk-tc';
    case 'hk-tc':
      return 'hk-sc';
    default:
      return '';
  }
};

export const handleDetectShowUpdateLocale = (country: string, locale: string) => {
  let isShow = false;

  MAPPING_LOCALES_COUNTRY.map(item => {
    const idxItem = item.locales.findIndex(localeItem => localeItem === locale);
    if (idxItem !== -1) {
      if (item.country.findIndex(countryItem => countryItem === country) !== -1) {
        isShow = true;
      } else {
        isShow = false;
      }
    }
  });
  return isShow;
};

export const handleDetectDefaultLocale = (country: string) => {
  let defaultValue = 'Southeast Asia (SEA)';

  for (let i = 0; i < MAPPING_LOCALES_COUNTRY.length; i++) {
    const idxItem = MAPPING_LOCALES_COUNTRY[i].country.findIndex(
      countryItem => countryItem === country
    );
    if (idxItem !== -1) {
      defaultValue = MAPPING_LOCALES_COUNTRY[i].name;
      break;
    }
  }

  return defaultValue;
};

export const handleRedirectLanguageUtil = (
  selectedLang: string,
  router: any,
  pathname: string,
  query: any,
  asPath: string,
  dataSlug: any,
  isNavigation?: boolean
) => {
  const {locale, route} = router;
  const newLocale = `${locale}`.toLowerCase();
  const splitPathName = route.split('/');

  // const dataSlugLocale =
  //   dataSlug[handleConvertToLocaleContentful(handleChangeLocale(locale as string))] ?? '';

  // Get the full cookie consent
  const cookieConsent = document.cookie
    ? document.cookie.split('; ').find(row => row.startsWith('hasCookieConsent='))
    : null;

  // Get the value of the cookie, note this will be a string
  const cookieConsentString = cookieConsent ? cookieConsent.split('=')[1] : false;

  // Extract the value to a boolean we can use more easily
  const hasCookieConsent = cookieConsentString === 'true';

  if (
    selectedLang === 'Southeast Asia (SEA)' ||
    selectedLang === '동남아시아' ||
    selectedLang === '东南亚' ||
    selectedLang === '東南亞'
  ) {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    if (isNavigation) {
      window.location.href =
        splitPathName?.length >= 3
          ? dataSlug[handleConvertToLocaleContentful('sea')]
            ? '/sea/' + splitPathName[1] + `/${dataSlug[handleConvertToLocaleContentful('sea')]}`
            : '/sea'
          : '/sea' + asPath;
    } else {
      window.location.href = '/sea' + asPath;
    }
  }

  if (
    selectedLang === 'Hong Kong' ||
    selectedLang === '홍콩' ||
    selectedLang === '香港' ||
    selectedLang === '香港'
  ) {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    setCookie('currentToken', 'HKD');
    // router.push({pathname, query}, asPath, {locale: 'hk-sc'});
    if (isNavigation) {
      window.location.href =
        splitPathName?.length >= 3
          ? dataSlug[handleConvertToLocaleContentful('hk-tc')]
            ? '/hk-tc/' +
              splitPathName[1] +
              `/${dataSlug[handleConvertToLocaleContentful('hk-tc')]}`
            : '/hk-tc'
          : '/hk-tc' + asPath;
    } else {
      window.location.href = (newLocale === 'tc' ? `/${newLocale}` : '/hk-tc') + asPath;
    }
  }

  if (
    selectedLang === 'Korea' ||
    selectedLang === '대한민국' ||
    selectedLang === '韩国' ||
    selectedLang === '韓國'
  ) {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }

    if (isNavigation) {
      window.location.href =
        splitPathName?.length >= 3
          ? dataSlug[handleConvertToLocaleContentful('ko-kr')]
            ? '/ko-kr/' +
              splitPathName[1] +
              `/${dataSlug[handleConvertToLocaleContentful('ko-kr')]}`
            : '/ko-kr'
          : '/ko-kr' + asPath;
      router.push({pathname, query}, asPath, {locale: 'ko-kr'});
    } else {
      window.location.href = '/ko-kr' + asPath;
    }
  }

  if (
    selectedLang === 'Taiwan' ||
    selectedLang === '대만' ||
    selectedLang === '台湾' ||
    selectedLang === '台灣'
  ) {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    // router.push({pathname, query}, asPath, {locale: 'tw-sc'});
    setCookie('currentToken', 'TWD');
    if (isNavigation) {
      window.location.href =
        splitPathName?.length >= 3
          ? dataSlug[handleConvertToLocaleContentful('tw-tc')]
            ? '/tw-tc/' +
              splitPathName[1] +
              `/${dataSlug[handleConvertToLocaleContentful('tw-tc')]}`
            : '/tw-tc'
          : '/tw-tc' + asPath;
    } else {
      window.location.href = (newLocale === 'tc' ? `/${newLocale}` : '/tw-tc') + asPath;
    }
  }

  if (
    selectedLang === 'America' ||
    selectedLang === '미국' ||
    selectedLang === '美国' ||
    selectedLang === '美國'
  ) {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    // router.push({pathname, query}, asPath, {locale: 'en-us'});
    // window.location.href = '/en-us' + asPath;
    window.location.href = 'https://www.bandainamcoent.com/';
  }

  if (
    selectedLang === 'Europe' ||
    selectedLang === '유럽' ||
    selectedLang === '欧洲' ||
    selectedLang === '歐洲'
  ) {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    // router.push({pathname, query}, asPath, {locale: 'eu'});
    // window.location.href = '/eu' + asPath;
    if (FOR_STORE?.includes(pathname)) {
      window.location.href = 'https://store.bandainamcoent.eu/';
      return;
    }
    window.location.href = 'https://en.bandainamcoent.eu/';
  }

  if (
    selectedLang === 'Japan' ||
    selectedLang === '일본' ||
    selectedLang === '日本' ||
    selectedLang === '日本'
  ) {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    // router.push({pathname, query}, asPath, {locale: 'ja'});
    // window.location.href = '/ja' + asPath;
    window.location.href = 'https://www.bandainamcoent.co.jp/';
  }
};

export const handleRedirectLocaleUtil = (
  selectedLang: string,
  router: any,
  pathname: string,
  query: any,
  asPath: string,
  dataSlug: any
) => {
  const {locale, route} = router;
  const splitPathName = route.split('/');
  // Get the full cookie consent
  const cookieConsent = document.cookie
    ? document.cookie.split('; ').find(row => row.startsWith('hasCookieConsent='))
    : null;

  // Get the value of the cookie, note this will be a string
  const cookieConsentString = cookieConsent ? cookieConsent.split('=')[1] : false;

  const dataSlugLocale =
    dataSlug[handleConvertToLocaleContentful(handleChangeLocale(locale as string))] ?? '';

  // Extract the value to a boolean we can use more easily
  const hasCookieConsent = cookieConsentString === 'true';

  if (locale === 'hk-sc') {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    // router.push({pathname, query}, asPath, {locale: 'hk-tc'});
    // window.location.href = '/hk-tc' + asPath;
    window.location.href =
      splitPathName?.length >= 3
        ? dataSlugLocale
          ? '/hk-tc/' + splitPathName[1] + `/${dataSlugLocale}`
          : '/hk-tc'
        : '/hk-tc' + asPath;
  }

  if (locale === 'hk-tc') {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    // router.push({pathname, query}, asPath, {locale: 'hk-sc'});
    // window.location.href = '/hk-sc' + asPath;
    window.location.href =
      splitPathName?.length >= 3
        ? dataSlugLocale
          ? '/hk-sc/' + splitPathName[1] + `/${dataSlugLocale}`
          : '/hk-sc'
        : '/hk-sc' + asPath;
  }

  if (locale === 'tw-sc') {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    // router.push({pathname, query}, asPath, {locale: 'tw-tc'});
    // window.location.href = splitPathName?.length >= 3 ? '' : '/tw-tc' + asPath;
    // window.location.href = '/tw-tc' + asPath;

    window.location.href =
      splitPathName?.length >= 3
        ? dataSlugLocale
          ? '/tw-tc/' + splitPathName[1] + `/${dataSlugLocale}`
          : '/tw-tc'
        : '/tw-tc' + asPath;
  }

  if (locale === 'tw-tc') {
    // If we have consent set a cookie
    if (hasCookieConsent) {
      document.cookie = `NEXT_LOCALE=en; maxage=${1000 * 60 * 60 * 24 * 7}; path=/`;
    }
    // router.push({pathname, query}, asPath, {locale: 'tw-sc'});
    // window.location.href = '/tw-sc' + asPath;
    window.location.href =
      splitPathName?.length >= 3
        ? dataSlugLocale
          ? '/tw-sc/' + splitPathName[1] + `/${dataSlugLocale}`
          : '/tw-sc'
        : '/tw-sc' + asPath;
  }
};

export const handleConvertLocaleToRegion = (locale: string) => {
  const localeLowercase = locale?.toLocaleLowerCase();
  if (localeLowercase === 'sea') {
    return 'Southeast Asia (SEA)';
  } else if (localeLowercase === 'eu') {
    return 'Europe';
  } else if (localeLowercase === 'ja') {
    return 'Japan';
  } else if (localeLowercase === 'ko-kr') {
    return '대한민국';
  } else if (localeLowercase === 'tw-tc') {
    return '台灣';
  } else if (localeLowercase === 'tw-sc') {
    return '台湾';
  } else if (localeLowercase === 'hk-sc') {
    return '香港';
  } else if (localeLowercase === 'hk-tc') {
    return '香港';
  } else if (localeLowercase === 'tc') {
    return '香港';
  } else if (localeLowercase === 'us' || localeLowercase === 'en-us') {
    return 'America';
  } else {
    return 'Southeast Asia (SEA)';
  }
};