import {IHomepageMasthead} from '@wearesection/bandai-react-components/organisms/HomepageMasthead';
import MastheadBlog, {
  IMastheadBlog,
} from '@wearesection/bandai-react-components/organisms/MastheadBlog';
import {Box} from '@mui/material';
import React from 'react';
import {PLACEHOLDER_IMG} from '~/constants';
import * as Types from '../../../../graphql/types';
import RenderNextImage from '../../atoms/render-next-image';
import {
  convertPlatformToIcon,
  convertToSlug,
  handleFilterTags,
  handleRenderPriceHomePageMashThead,
  handleReturnCurrency,
  handleReturnIDVideo,
  handleReturnImg,
  handleReturnTypeVideo,
  handleReturnVideoDirectly,
} from '~/utils';
import {GetRouter} from '~/utils';
import dynamic from 'next/dynamic';
const HomepageMashThead = dynamic(
  () => import('@wearesection/bandai-react-components/organisms/HomepageMasthead'),
  {
    ssr: false,
  }
);
type IHomepageMashTheadExt = IHomepageMasthead & IMastheadBlog;

export const handleReturnLocaleStore = (locale: string) => {
  if (['hk-tc', 'hk-sc', 'tw-tc', 'tw-sc'].includes(`${locale}`.toLowerCase())) {
    return 'tc';
  }
  return locale;
};

const adapter = (data: any): IHomepageMashTheadExt => {
  const {locale} = GetRouter();
  const BCNameGame = data?.content?.bcProductReference?.site?.product?.name || '';
  if (data?.content?.__typename === 'Game') {
    const valueMashThead: any = data.content;
    const newData: any = {
      title: valueMashThead?.title ?? '',
      description: valueMashThead?.overviewTitle ?? '',
      videoType: handleReturnTypeVideo(
        valueMashThead?.mastheadBackgroundImage?.videoEmbed as string
      ),
      idVideo: handleReturnVideoDirectly(
        valueMashThead?.mastheadBackgroundImage?.mediaFile?.url as string
      )
        ? valueMashThead?.mastheadBackgroundImage?.mediaFile?.url
        : handleReturnIDVideo(valueMashThead?.mastheadBackgroundImage?.videoEmbed as string) || '',
      backgroundImage: valueMashThead?.mastheadBackgroundImage?.media?.url ?? PLACEHOLDER_IMG,
      locale: locale?.toLocaleLowerCase() ?? '',
      listTags:
        handleFilterTags(valueMashThead?.platform as [])?.map((item: any) => ({
          color: '#737373',
          title: React.createElement(convertPlatformToIcon(item)),
          disabled: false,
        })) || [],
      price: BCNameGame
        ? {
            title:
              `${data?.locale}`.toLowerCase() === 'ko-kr'
                ? ''
                : data?.dataTranslation['product-from'] || '',
            value: `${
              `${data?.locale}`.toLowerCase() !== 'ko-kr'
                ? handleReturnCurrency(data?.locale) || ''
                : ''
            }${handleRenderPriceHomePageMashThead(
              data?.content?.bcProductReference?.site?.product?.prices?.price?.value || '',
              locale
            )}${
              `${data?.locale}`.toLowerCase() === 'ko-kr'
                ? `${handleReturnCurrency(data?.locale)} 부터` || ''
                : ''
            }`,
          }
        : {},
      valueButtonBuy: valueMashThead?.slug ? `/news/${valueMashThead?.slug}` : '',
      listTrailer:
        valueMashThead?.mastheadGalleryCollection?.items?.map((item: Types.ContentTypeAsset) => {
          return {
            nextImage: (
              <RenderNextImage
                src={
                  handleReturnImg(item?.videoEmbed as string, item) ??
                  item?.media?.url ??
                  PLACEHOLDER_IMG
                }
                width={263}
                height={147}
                alt={item?.alt || ''}
              />
            ),
            trailerUrl: handleReturnIDVideo(item?.videoEmbed as string) ?? '',
            trailerTitle: item?.caption ?? '',
            trailerImg:
              handleReturnImg(item?.videoEmbed as string, item) ||
              item?.media?.url ||
              PLACEHOLDER_IMG,
            videoType: handleReturnTypeVideo(item?.videoEmbed as string) ?? '',
          };
        }) || [],
      titleButtonBuy: BCNameGame ? data?.dataTranslation['product-buy'] : '',
      // Web Launch comment
      hrefButtonBuy: BCNameGame
        ? `${process.env.NEXT_PUBLIC_STORE_SITE_URL}${handleReturnLocaleStore(
            locale || ''
          )}/store/games/${convertToSlug(BCNameGame)}`
        : '',
      titleButtonWatch: valueMashThead?.mastheadGalleryCollection?.items?.length
        ? data?.dataTranslation['game-detailed-watch-trailer']
        : '',
      videoDirectly:
        handleReturnVideoDirectly(
          valueMashThead?.mastheadBackgroundImage?.mediaFile?.url as string
        ) ?? false,
    };

    return newData;
  } else {
    const newData: any = {
      mainImage: (data?.content as any)?.media?.url ?? PLACEHOLDER_IMG,
      mainImageNext: (
        <RenderNextImage
          src={(data?.content as any)?.media?.url ?? PLACEHOLDER_IMG}
          width={1920}
          height={364}
          alt={(data?.content as any)?.title || ''}
        />
      ),
    };
    return newData;
  }
};

const HomepageMashTheadOrganisms: React.FC<Types.Mashthead & any> = props => {
  const ComponentProps = adapter(props);
  if (props?.content?.__typename === 'Game') {
    return <HomepageMashThead {...ComponentProps} />;
  } else {
    return (
      <Box
        sx={{
          '& div': {
            borderRadius: '0px',
          },
        }}
      >
        <MastheadBlog {...ComponentProps} />
      </Box>
    );
  }
};

export default HomepageMashTheadOrganisms;
